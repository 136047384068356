import React from 'react';
import InputField from './Components/InputField'
import TextAreaField from './Components/TextAreaField'
import OptionField from './Components/OptionField'
import CheckboxField from './Components/CheckboxField'
import SubmitButton from './Components/SubmitButton'


class UmbracoForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    const nodeId = document.querySelector('#react-form-umbraco').dataset.node;

    this.constants = {
      response: {
        LOADING: "LOADING",

      }
    }

    this.state = {
      formFields: [],
      submitButton: "",
      token: "",
      isLoading: false,
      nodeId: nodeId,
      response: ""
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    fetch("/Umbraco/Api/ReactForm/GetFormStructure?currentPageId=" + this.state.nodeId)
      .then(response => response.json())
      .then(data => this.setState({ formFields: data.fields, submitButton: data.submitText, token: data.token, isLoading: false }));
  }

  handleSubmit(event) {

    this.setState({ response: this.constants.response.LOADING });

    const formData = new FormData(event.target);
    event.preventDefault();

    const arrayData = [];
    for (let [key, value] of formData.entries()) {
      arrayData.push({ key, value });
    }

    const body = {
      nodeId: this.state.nodeId,
      token: this.state.token,
      data: JSON.stringify(arrayData)
    }

    fetch('/Umbraco/Api/ReactForm/Submit', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(response => this.setState({ response: response }));
  }

  render() {
    const { formFields, submitButton, isLoading, response } = this.state;
    const responseType = this.constants.response;

    if (response === "" || response === responseType.LOADING) {
      if (isLoading) {
        return (
          <p>Loading...</p>
        )
      }

      if (!isLoading && formFields.length > 0) {

        const submitDisabled = this.state.response === responseType.LOADING;
        return (
          <form onSubmit={this.handleSubmit} autoComplete="off">
            {formFields.map((field, index) => {
              if(field.type === "textarea"){
                return (<TextAreaField key={index} index={index} field={field} />)
              }
              if(field.type === "dropdown"){
                return (<OptionField key={index} index={index} field={field} />)
              }
              if(field.type === "checkbox"){
                return (<CheckboxField key={index} index={index} field={field} />)
              }
              return (<InputField key={index} index={index} field={field} />)
            })}
            <SubmitButton text={submitButton} isDisabled={submitDisabled}></SubmitButton>
          </form>
        )
      }

      return (
        <p>No data to display. It doesn't look like fields have been created for this form.</p>
      )
    } 
    if(response.redirectUrl !== undefined && response.redirectUrl !== ""){      
      return window.location.href = response.redirectUrl;
    }
    return (
      <p>{response.message}</p>
      )
  }
}
export default UmbracoForm;