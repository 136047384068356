import React from 'react';
import SmallPrint from "./SmallPrint";

class OptionField extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.options = this.props.field.options.split("\n");
  }

  render() {
    return (
      <div className="form-group">
        <label key={this.props.index} htmlFor={this.props.field.name + "_" + this.props.index}>
          {this.props.field.label}
        </label>
        <select className="form-control" id={this.props.field.name + "_" + this.props.index}
          value={this.props.field.value}
          name={this.props.field.name}
          required={this.props.field.required}
          placeholder={this.props.field.placeholder}
          autoComplete="off"
        >
          {this.options.map((x, y) => <option key={y} value={x}>{x}</option>)}
        </select>
        <SmallPrint text={this.props.field.description}></SmallPrint>
      </div>
    );
  }
}
export default OptionField;