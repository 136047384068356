import React from 'react';
import SmallPrint from "./SmallPrint";

class CheckboxField extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.options = this.props.field.options.split("\n");
  }

  render() {
    return (
      <div className="checkbox">
        <label>{this.props.field.label}</label>
        <br></br>
          {this.options.map((x, y) => 
            <label key={y} htmlFor={this.props.field.name + "_" + this.props.index}>
              <input type="checkbox" key={y} value={x} name={this.props.field.name} />{x}
            </label>
          )}
        <SmallPrint text={this.props.field.description}></SmallPrint>
      </div>
    );
  }
}
export default CheckboxField;