import React from 'react';
import SmallPrint from "./SmallPrint";

class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div className="form-group">
        <label key={this.props.index} htmlFor={this.props.field.name + "_" + this.props.index}>
          {this.props.field.label}
        </label>
        <input className="form-control" id={this.props.field.name + "_" + this.props.index}
        type={this.props.field.type}
        value={this.props.field.value}
        name={this.props.field.name}
        required={this.props.field.required}
        placeholder={this.props.field.placeholder}
        autoComplete="off"
        ></input>
        <SmallPrint text={this.props.field.description}></SmallPrint>
      </div>
    );
  }
}
export default InputField;