import React from 'react';

class SubmitButton extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <button type="submit" disabled={this.props.isDisabled} className="btn btn-primary">{this.props.text}</button>
    );
  }
}
export default SubmitButton;