import React from 'react';

class SmallPrint extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    if (this.props.text) {
      return (
        <small>{this.props.text}</small>
      )
    }
    return("");
  }
}
export default SmallPrint;